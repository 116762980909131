<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent page-title"
    id="header"
    :class="{
      'md-toolbar-sticky md-white md-fixed-top top-navbar-sticky': true,
    }"
  >
    <div class="md-toolbar-row top-navbar">
      <div class="md-toolbar-section-start freeze-top" id="freezeTopNav">
        <h3 v-if="headingTitle" class="md-title">
          <span>{{ headingTitle }}</span>
        </h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <md-list class="list-icon-right-side">
            <md-list-item href="/">
              <md-icon>home</md-icon>
              <p class="hidden-lg hidden-md">Home</p>
            </md-list-item>

            <li class="md-list-item">
              <div class="md-list-item-content">
                <drop-down direction="down">
                  <md-button slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown">
                    <md-icon>notifications</md-icon>
                    <span v-if="numberOfUnreadMessages" class="notification">{{ numberOfUnreadMessages }}</span>
                    <p class="hidden-lg hidden-md">Notifications</p>
                  </md-button>
                  <ul v-if="notificationList.length" class="dropdown-menu dropdown-menu-right notification-list">
                    <li
                      v-for="(notification, index) in notificationList"
                      :key="index"
                      :class="index + 1 === notificationList.length ? 'can-clickable' : ''"
                    >
                      <a @click="onClickNotificationIcon(index + 1)">{{ notification }}</a>
                    </li>
                  </ul>
                </drop-down>
              </div>
            </li>

            <md-list-item v-if="isPathView" href="/my-account/calendar">
              <md-icon>calendar_month</md-icon>
              <p class="hidden-lg hidden-md">Calendar</p>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import { APP_ROUTES } from '@/core/constants';
import { mapGetters, mapState } from 'vuex';
import notificationsService from '@/services/notifications.service';

window.onscroll = function () {
  const element = document.getElementById('freezeTopNav');
  if (element !== null) {
    if (window.pageYOffset > 100) {
      element.classList.add('isShow');
    } else {
      element.classList.remove('isShow');
    }
  }

  const elAnchorSytem = document.getElementById('anchor-system');
  if (elAnchorSytem != null) {
    if (window.pageYOffset > 80) {
      elAnchorSytem.classList.add('isSticky');
    } else {
      elAnchorSytem.classList.remove('isSticky');
    }
  }
};

export default {
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters('app', ['headingTitle', 'numberOfUnreadMessages']),
    ...mapState('auth', {
      userId: (state) => state.user.userId,
    }),
    isPathView() {
      const userType = this.$store.getters['auth/userType'] || '';
      return userType === this.USER_TYPES()?.Pathologist;
    },
  },
  data() {
    return {
      notificationList: [],
    };
  },
  methods: {
    async fetchData() {
      await this.getNotificationList();
    },
    async getNotificationList() {
      const { error, data } = await notificationsService.search({
        filters: {},
        pagination: {},
        userId: this.userId,
      });

      if (error) {
        this.$alertError(error);
        return;
      }

      if (data && data.notifications.items.length > 0) {
        data.notifications.items.forEach((item, index) => {
          if (index < 5) {
            this.notificationList.push(item.subject);
          }
        });
        this.notificationList.push(this.$t('pages/Dashboard/Components/TopNavbar/notification/clickAll'));
      }
    },
    onClickNotificationIcon(index) {
      if (index === this.notificationList.length) {
        this.$router.push(APP_ROUTES.NOTIFICATION);
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
};
</script>
<style lang="scss">
.can-clickable {
  cursor: pointer;
  color: #000;
  background-color: #dddddd;
  width: fit-content;
  margin-left: 30px;
  border-radius: 3px;
}
</style>
