<template>
  <div class="user">
    <div class="photo" v-if="user.avatar">
      <img :src="user.avatar" alt="avatar" />
    </div>
    <div class="photo" v-if="!user.avatar"><md-icon>person</md-icon></div>
    <div class="user-info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click.stop="toggleMenu" @click.capture="clicked">
        <span v-if="user">
          {{ getFullName(user) }}
          <b class="caret"></b>
        </span>
      </a>

      <div v-show="!isClosed">
        <ul class="nav">
          <li v-for="(menuItem, menuItemIndex) in getMyAccountMenu" :key="menuItemIndex" class="user-menu-item">
            <router-link :to="menuItem.path">
              <md-icon>{{ menuItem.icon }}</md-icon>
              <span class="sidebar-normal">{{ $t(menuItem.name) }}</span>
            </router-link>
          </li>
          <li class="user-menu-item">
            <a v-show="true" v-on:click="doLogout">
              <md-icon>logout</md-icon>
              <span class="sidebar-normal">{{ $t('menu.logout') }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
// import { CollapseTransition } from 'vue2-transitions';

import { getMyAccountMenuWithRestrictionRules, MY_ACCOUNT_MENU } from '@/core/menus';
import { mapState } from 'vuex';

export default {
  components: {
    // CollapseTransition,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isClosed: true,
    };
  },
  computed: {
    ...mapState('auth', {
      permissions: (state) => state.permissions,
    }),
    getMyAccountMenu() {
      const myAccountMenus = ((menuItems) => JSON.parse(JSON.stringify(menuItems)))(MY_ACCOUNT_MENU);
      return getMyAccountMenuWithRestrictionRules(
        { userPermissions: this.permissions, $t: (k) => this.$translate(k) },
        myAccountMenus,
      );
    },
  },
  methods: {
    getFullName(user) {
      return `${user.userName}`;
      // return `${user.firstName} ${user.lastName}`;
    },
    clicked: function (e) {
      e.preventDefault();
    },
    toggleMenu: function () {
      this.isClosed = !this.isClosed;
    },
    doLogout() {
      this.$store.dispatch('auth/DO_LOGOUT');
    },
  },
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
