<template>
  <footer class="footer">
    <modal-show-policy slot="blockTitle" ref="policyModal" :pdf-base64="fileURL"></modal-show-policy>
    <div class="container">
      <nav>
        <ul>
          <li v-for="link in policyAcceptedByUser" :key="link.policyId">
            <a href="javascript:void();" @click="onViewPolicy(link.policyId)">{{ link.title }}</a>
          </li>
        </ul>
      </nav>
      <div class="copyright text-center" v-html="$t('footer.copyright')"></div>
    </div>
  </footer>
</template>
<script>
import ModalShowPolicy from '@/components/Lims/modals/ModalShowPolicy';

import { ConfigMixins } from '@/core/mixins';

import PolicyService from '@/services/policy.service';

export default {
  mixins: [ConfigMixins],
  components: {
    ModalShowPolicy,
  },
  computed: {
    policyAcceptedByUser() {
      return this.$appConfig.policyAcceptedByUser;
    },
  },
  data: function () {
    return {
      fileURL: null,
    };
  },
  methods: {
    async onViewPolicy(policyId) {
      const res = await PolicyService.downloadFilePolicy(policyId);
      this.fileURL = URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      this.$refs.policyModal.open();
    },
  },
};
</script>
<style></style>
