// MenuItems from pages or modules
// import ExampleMenu from '@/pages/Example/example-menu';
import { CUSTOM_USER_TYPE_PERMISSIONS } from '@/core/constants';

// Menu utils
const isNotRestrictedMenuItem = ({ permissions, userPermissions }) => {
  if (!permissions) return true;
  // TO CHECK: userPermissions must intersect with permissions at least 1
  return permissions.filter((x) => userPermissions.includes(x)).length;
};
const convertMenuItemToLink = (menuItem, $t, { isParent, userPermissions }) => {
  const { id, name, path, icon } = menuItem;
  // 1st level - no children : {name: 'Dashboard', icon: 'dashboard', path: '/dashboard'}
  // 1st level - have children : name: 'Dashboard', icon: 'dashboard'}
  // 2nd level : name: 'Dashboard' , path: '/dashboard'
  // transform childrens if 1st level and have childrens
  // permission check
  if (isParent && !menuItem.childrens) {
    return {
      id,
      link: { name: $t(name), icon, path },
    };
  }
  if (isParent && menuItem.childrens && menuItem.childrens.length > 0) {
    // transform childrens
    menuItem.childrens = menuItem.childrens.map((secondLevelMenuItem) => {
      return convertMenuItemToLink(secondLevelMenuItem, $t, { isParent: false, userPermissions });
    });
    return {
      id,
      link: { name: $t(name), icon, path },
      childrens: menuItem.childrens,
    };
  }
  return {
    id,
    link: { name: $t(name), path },
  };
};

const menuReducer = (menuItems, { $t, userPermissions }) => {
  const allowMenuItems = menuItems.map((firstLevelMenuItem) => {
    return convertMenuItemToLink(firstLevelMenuItem, $t, { isParent: true, userPermissions });
  });
  return allowMenuItems;
};

export const MY_ACCOUNT_MENU = [
  { name: 'menu.myAccount.profile', path: '/my-account', icon: 'person' },
  { name: 'menu.myAccount.password', path: '/my-account/password', icon: 'password' },
  { name: 'menu.myAccount.settings', path: '/my-account/settings', icon: 'settings' },
  {
    name: 'menu.myAccount.notifications',
    path: '/my-account/notifications',
    icon: 'notifications',
  },
  {
    name: 'menu.myAccount.calendar',
    path: '/my-account/calendar',
    icon: 'event_available',
    permissions: [CUSTOM_USER_TYPE_PERMISSIONS.UserType_Pathologist],
  },
  {
    name: 'menu.myAccount.personalizedReport',
    path: '/personalised-reports',
    icon: 'notifications',
    permissions: ['My_Personalised_Report'],
  },
];

export const MAIN_MENU = [
  {
    id: 'accounts',
    name: 'menu.accounts',
    path: '/accounts',
    icon: 'manage_accounts',
    permissions: ['Accounts_RoleManagement_Retrieve', 'Accounts_UserManagement_Retrieve'],
    childrens: [
      {
        id: 'roleManagement',
        name: 'menu.accounts.roleManagement',
        path: '/roles',
        icon: '',
        permissions: ['Accounts_RoleManagement_Retrieve'],
      },
      {
        id: 'userManagement',
        name: 'menu.accounts.userManagement',
        path: '/users',
        icon: '',
        permissions: ['Accounts_UserManagement_Retrieve'],
      },
    ],
  },
  {
    id: 'cases',
    name: 'menu.cases',
    path: '/cases',
    icon: 'earbuds',
    permissions: null,
    childrens: [
      {
        id: 'alwManagement',
        name: 'menu.cases.alwManagement',
        path: '/cases/alw-management',
        icon: '',
        permissions: ['Case_ALWManagement_ProcessALW'],
      },
      {
        id: 'caseManagement',
        name: 'menu.cases.caseManagement',
        path: '/cases',
        icon: '',
        permissions: null,
      },
      {
        id: 'addCases',
        name: 'menu.cases.addCases',
        path: '/cases/add',
        icon: '',
        permissions: ['Case_CaseForm_Create'],
      },
      {
        id: 'caseOfInterest',
        name: 'menu.cases.casesOfInterest',
        path: '/cases-of-interest',
        icon: '',
        permissions: null,
      },
      {
        id: 'importCase',
        name: 'menu.cases.importCase',
        path: '/import-case',
        icon: '',
        permissions: ['Case_CaseImport_Create'],
      },
      {
        id: 'importHistory',
        name: 'menu.cases.importHistory',
        path: '/import-history',
        icon: '',
        permissions: ['Case_CaseImport_Retrieve'],
      },
      {
        id: 'requestManagement',
        name: 'menu.cases.requestManagement',
        path: '/request',
        icon: '',
        permissions: ['Case_AnonymiseInformation-Approve_Validate'],
      },
      {
        id: 'slideManagement',
        name: 'menu.cases.slideManagement',
        path: '/slides',
        icon: '',
        permissions: ['Case_SlideManagement_Retrieve'],
      },
      {
        id: 'OCRUploadRF',
        name: 'menu.cases.OCRUploadRF',
        path: '/ocr-rf-upload',
        icon: '',
        permissions: ['Case_OCR_Create'],
      },
      {
        id: 'OcrUploadHistory',
        name: 'menu.cases.OcrUploadHistory',
        path: '/ocr-upload-history',
        icon: '',
        permissions: ['Case_OCR_Retrieve'],
      },
      {
        id: 'simpleBookingForm',
        name: 'menu.cases.simpleBooking',
        path: '/simple-booking',
        icon: '',
        permissions: ['Case_SimpleBookingForm_Create'],
      },
    ],
  },
  {
    id: 'entities',
    name: 'menu.entities',
    path: '/entities',
    icon: 'supervisor_account',
    permissions: ['Accounts_EntityManagement_Retrieve', CUSTOM_USER_TYPE_PERMISSIONS.UserType_Administrator],
    childrens: [
      {
        id: 'entity',
        name: 'menu.entities.entityManagement',
        path: '/entities',
        icon: '',
        permissions: ['Accounts_EntityManagement_Retrieve'],
      },
      {
        id: 'datasetManagement',
        name: 'menu.entities.datasetManagement',
        path: '/dataset',
        icon: '',
        permissions: ['MyEntity_DatasetManagement_Retrieve'],
      },
    ],
  },
  {
    id: 'myEntity',
    name: 'menu.myEntity',
    path: '/my-entity',
    icon: 'portrait',
    permissions: [
      'MyEntity_EntityInformation_Retrieve',
      'MyEntity_UserManagement_Retrieve',
      CUSTOM_USER_TYPE_PERMISSIONS.UserType_ClinicAssociate,
    ],
    childrens: [
      {
        id: 'myEntityProfile',
        name: 'menu.myEntity.profile',
        path: '/my-entity/profile',
        icon: '',
        permissions: ['MyEntity_EntityInformation_Retrieve'],
      },
      {
        id: 'myEntityUsers',
        name: 'menu.myEntity.userManagement',
        path: '/my-entity/users',
        icon: '',
        permissions: ['MyEntity_UserManagement_Retrieve'],
      },
      {
        id: 'datasetManagement',
        name: 'menu.entities.datasetManagement',
        path: '/dataset',
        icon: '',
        permissions: ['MyEntity_DatasetManagement_Retrieve'],
      },
    ],
  },
  {
    id: 'globalVariables',
    name: 'menu.globalVariables',
    path: '/global-variables',
    icon: 'public',
    permissions: ['GlobalVariables_GlobalVariables_Retrieve'],
  },
  {
    id: 'policies',
    name: 'menu.policies',
    path: '/policies',
    icon: 'policy',
    permissions: ['Policy_PolicyManagement_Retrieve'],
    childrens: [
      {
        id: 'policy',
        name: 'menu.policies.policyManagement',
        path: '/policies',
        icon: '',
        permissions: ['Policy_PolicyManagement_Retrieve'],
      },
    ],
  },
  {
    id: 'data',
    name: 'menu.data',
    path: '/data',
    icon: 'folder',
    permissions: ['Data_DropdownManagement_Retrieve', 'Case_PersonalizedReports_Retrieve'],
    childrens: [
      {
        id: 'dropdownManagement',
        name: 'menu.data.dropdownManagement',
        path: '/dropdown',
        icon: '',
        permissions: ['Data_DropdownManagement_Retrieve'],
      },
      {
        id: 'personalizedReport',
        name: 'menu.data.personalizedReport',
        path: '/personalised-reports',
        icon: '',
        permissions: ['Case_PersonalizedReports_Retrieve'],
      },
    ],
  },
];

export const getMenuWithRestrictionRules = ({ userPermissions, $t }, menu = MAIN_MENU) => {
  const allowedMenuItems = menu
    .filter(({ permissions }) =>
      isNotRestrictedMenuItem({
        permissions,
        userPermissions,
      }),
    )
    .map((item) => {
      if (item.childrens) {
        item.childrens = item.childrens.filter(({ permissions }) =>
          isNotRestrictedMenuItem({
            permissions,
            userPermissions,
          }),
        );
      }
      return item;
    });
  return menuReducer(allowedMenuItems, { $t, userPermissions });
};

export const getMyAccountMenuWithRestrictionRules = ({ userPermissions }, menu = MY_ACCOUNT_MENU) => {
  return menu.filter(({ permissions }) =>
    isNotRestrictedMenuItem({
      permissions,
      userPermissions,
    }),
  );
};
